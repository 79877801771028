import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import React from 'react'
import styled from 'styled-components'
import { graphql, Link } from 'gatsby'

import Button from '../components/atoms/Button'

const Excerpt = styled.p`
  font-size: 0.95em;
`

const BlogPostItem = ({
  index,
  title,
  slug,
  image,
  publishDate,
  author,
  category,
  categorySlug,
  page = 'blog',
  excerpt,
  className,
  avatar,
  body,
}) => {
  const regex = /<p>(.*?)<\/p>/
  const corresp = regex.exec(body)
  let firstParagraphWithoutHtml = corresp ? corresp[1] : ''

  // Replace common HTML entities like &#39; with actual characters
  firstParagraphWithoutHtml = firstParagraphWithoutHtml.replace(/&#39;/g, "'")

  // Remove any remaining HTML tags
  firstParagraphWithoutHtml = firstParagraphWithoutHtml.replace(/<[^>]*>/g, '')

  return (
    <div className={`${index == 0 ? 'w-full my-4' : ' w-full md:w-1/3'} px-4`}>
      <div
        className={`${
          index == 0 ? 'flex flex-wrap' : ''
        } mb-4 rounded-2xl shadow-md overflow-hidden bg-white`}
      >
        <div
          className={`${index == 0 ? 'md:order-2 w-full md:w-1/2' : ''}`}
          style={{ margin: 0 }}
        >
          <GatsbyImage
            image={getImage(image)}
            alt={title}
            style={{
              width: '100%',
              height: index == 0 ? '100%' : '200px',
              objectFit: 'cover',
            }}
            className="h-full"
          />
        </div>

        <div
          className={`${
            index == 0 ? 'w-full md:w-1/2 mb-0 m-0 md:p-12 ' : ''
          } p-8`}
        >
          <span
            className={`block font-display font-bold ${
              index == 0 ? 'text-xl md:text-2xl md:text-3xl' : 'text-xl'
            }`}
          >
            {title}
          </span>
          <p className="text-gray-500 py-3 leading-6">
            {firstParagraphWithoutHtml.slice(0, index == 0 ? 350 : 140)}...
          </p>

          <div className="flex py-4 items-center">
            <GatsbyImage
              className="user-reviewer-photo rounded-full"
              style={{ width: '45px', height: '45px' }}
              image={getImage(avatar)}
            />
            <div>
              <span className="block font-display text-gray-800 text-sm">
                by {author}
              </span>
              <span className="block text-gray-500 text-sm font-display font-normal">
                {publishDate}{' '}
                {category && (
                  <>
                    in{' '}
                    <Link
                      className="text-brand-500"
                      to={'/blog/category/' + categorySlug + '/'}
                      title={'View all posts in ' + category}
                    >
                      {category}
                    </Link>
                  </>
                )}
              </span>
            </div>
          </div>

          <Button to={`/${page}/` + slug} title={'Read more about ' + title}>
            Read More
          </Button>
        </div>
      </div>
    </div>
  )
}

export default BlogPostItem
